/* eslint-disable @typescript-eslint/no-explicit-any */
import * as map from '@/dataMappers/mapImage'

export const simpleHeader = (header: any) => (header ? {
  ...header,
  background: map.image(header?.background),
}: undefined)

export const simpleHeaderHero = (header: any) => ({
  ...header,
  background: map.image(header.background),
  heroCard: header.heroCard ? {
    ...header.heroCard,
    image: map.image(header.heroCard?.image),
  }: undefined,
})

export const basicSection = (section: any) => (section ? {
  ...section,
  image: map.image(section?.image),
}: undefined)

<script setup lang="ts">
import useImageHelper from '@/composables/useImageHelper'
import { IImage } from '@/interface/IImage'
import { computed, type PropType } from 'vue'

const props = defineProps({
  src: {
    type: [Object, String] as PropType<IImage | string>,
  },
})

const { getImageSrc, getPlaceholderSrc } = useImageHelper()

const url = computed(() => getImageSrc(props.src))
const placeholderUrl = computed(() => getPlaceholderSrc(props.src))
</script>
<template>
  <NuxtImg
    v-if="url && url !== ''"
    :src="url"
    :alt="src ? typeof src === 'string' ? src : src.small : 'image'"
    :style="{ aspectRatio: `${typeof src === 'object' ? src.aspect : 'auto'}` }"
    :placeholder="placeholderUrl"
    format="webp"
    loading="lazy"
  />
</template>

import { defineStore } from 'pinia'
import { guardedFetch } from './home.store'
import { map, mapCareers } from '@/dataMappers'
import type { IBasicHeaderHero } from '@/interface/IBasicHeaderHero'
import type { ICareerTeam } from '@/interface/careers/ICareersTeam'
import type { ICareerJoin } from '@/interface/careers/ICareerJoin'
import type { ICareerOurOffer } from '@/interface/careers/ICareerOurOffer'
import type { ICareerContact } from '@/interface/careers/ICareerContact'
import { IBaseResponse } from '@/interface/IBasicSection'

export const useCareersStore = defineStore({
  id: 'careers',
  state: () => ({
    headerSection: <IBasicHeaderHero|null> null,
    teamSection: <ICareerTeam|null> null,
    joinCompanySection: <ICareerJoin|null> null,
    jobOfferSection: <string|null>null,
    ourOfferSection: <ICareerOurOffer|null> null,
    contactSection: <ICareerContact|null> null,
    shouldFetch: true,
    loading: false,
  }),
  getters: {
    getHeader: state => state.headerSection,
    getTeam: state => state.teamSection,
    getJoinCompany: state => state.joinCompanySection,
    getJobOffer: state => state.jobOfferSection,
    getOurOffer: state => state.ourOfferSection,
    getContact: state => state.contactSection,
  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        try {
          const config = useRuntimeConfig()
          this.loading = true

          const { data } = await useAsyncData('careers-page', () =>
            $fetch(`${config.public.baseUrl}/api/careers-page?populate=deep`)
          )

          const {
            headerSection,
            teamSection,
            joinCompanySection,
            jobOfferSection,
            ourOfferSection,
            contactFormSection,
            contactDescribeSection,
          } = (data.value as IBaseResponse).data.attributes

          if (headerSection) this.headerSection = map.simpleHeaderHero(headerSection)
          if (teamSection) this.teamSection = mapCareers.team(teamSection)
          if (joinCompanySection) this.joinCompanySection = joinCompanySection
          if (jobOfferSection.title) this.jobOfferSection = jobOfferSection.title
          if (ourOfferSection) this.ourOfferSection = mapCareers.ourOffer(ourOfferSection)
          if (contactFormSection && contactDescribeSection)
            this.contactSection = mapCareers.contact(
              contactFormSection,
              contactDescribeSection
            )

          this.shouldFetch = false
          this.loading = false
        } catch (error) {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },
  },
})

import type { IOurModelSection } from '@/interface/ourModel/IOurModelSection'
import { map, mapOurModel } from '@/dataMappers/index'
import { guardedFetch } from './home.store'
import { defineStore } from 'pinia'
import { IBaseResponse } from '@/interface/IBasicSection'
import { IOurModelContact } from '@/interface/ourModel/IOurModelContact'
import { IHomeHeader } from '@/interface/home/IHomeHeader'
import { ICommonSideChanger } from '@/interface/commonElements/ICommonSideChanger'

export const useOurModelStore = defineStore({
  id: 'ourmodel',
  state: () => ({
    headerSection: <IHomeHeader|null> null,
    sections: <IOurModelSection[]|null> null,
    contactSection: <IOurModelContact|null> null,
    sideChanger: <ICommonSideChanger|null> null,
    shouldFetch: true,
    loading: false,
  }),
  getters: {
    getHeader: (state) => state.headerSection,
    getSections: (state) => state.sections,
    getContact: (state) => state.contactSection,
    getSideChanger: (state) => state.sideChanger,
  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        try {
          this.loading = true
          const config = useRuntimeConfig()
          const { data } = await useAsyncData('our-model', () =>
            $fetch(`${config.public.baseUrl}/api/our-model-page?populate=deep`),
            { server: true }
          )

          const {
            ourHeaderSection,
            sections,
            contactSection,
            ourSideChanger,
          } = (data.value as IBaseResponse).data.attributes
          
          if (ourHeaderSection) this.headerSection = map.simpleHeader(ourHeaderSection)
          if (sections) this.sections = mapOurModel.sections(sections)
          if (contactSection) this.contactSection = contactSection
          if (ourSideChanger) this.sideChanger = ourSideChanger
          this.shouldFetch = false
          this.loading = false
        } catch (error) {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },
  },
})

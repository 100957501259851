import { IImage } from '@/interface/IImage'
import { useWindow } from '@/composables/useWindow'

export default function useImageHelper() {
  const getImageSrc = (img: IImage | string | undefined | null) => {
    const config = useRuntimeConfig();
    const screen = useWindow()
    
    if (!img) return 'undefined'
    if (typeof img === 'string') return config.public.baseUrl + img
    return (
      config.public.baseUrl + (screen.value.mobile ? img?.small : img?.large)
    ).replace('uploads', 'uploads/format_webp')
  }

  const getPlaceholderSrc = (img: IImage | string | undefined | null) => {
    const config = useRuntimeConfig();
    if (!img || typeof img === 'string') return [50, 25, 75, 5]
    return (config.public.baseUrl + img?.small).replace('uploads', 'uploads/format_webp')
  }

  return {
    getImageSrc,
    getPlaceholderSrc,
  }
}

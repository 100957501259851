import { useScreen, useGrid } from 'vue-screen'
import { computed } from 'vue'

const screen = useScreen()
const grid = useGrid({
  a: 768,
  b: 1280,
  mobile: grid => !grid.a,
  tablet: grid => grid.a && !grid.b,
  desktop: grid => grid.b,
})

export const useWindow = () => computed(() => ({
    ...screen,
    mobile: grid.mobile,
    tablet: grid.tablet,
    desktop: grid.desktop,
    state: grid.mobile ? 1 : (grid.tablet ? 3 : 5)
}))

import { map } from '@/dataMappers/index'
import { guardedFetch } from './home.store'
import { defineStore } from 'pinia'
import { IBaseResponse, IBasicSection } from '@/interface/IBasicSection'
import { ICommonSideChanger } from '@/interface/commonElements/ICommonSideChanger'
import { IBasicHeaderHero } from '@/interface/IBasicHeaderHero'
import { ISoftwareSection } from '@/interface/software/ISoftwareSection'

export const useSoftwareStore = defineStore({
  id: 'software',
  state: () => ({
    headerSection: <IBasicHeaderHero | null>null,
    softwareSection: <ISoftwareSection | null>null,
    recomendationSection: <IBasicSection | null>null,
    contactSection: <IBasicSection | null>null,
    sideChanger: <ICommonSideChanger | null>null,
    shouldFetch: true,
    loading: false,
  }),
  getters: {
    getHeader: state => state.headerSection,
    getSoftwareSection: state => state.softwareSection,
    getRecomendationSection: state => state.recomendationSection,
    getContactSection: state => state.contactSection,
    getSideChanger: state => state.sideChanger,
  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        try {
          this.loading = true
          const config = useRuntimeConfig()
          const { data } = await useAsyncData(
            'software-page',
            () =>
              $fetch(
                `${config.public.baseUrl}/api/software-page?populate=deep`
              ),
            { server: true }
          )

          const {
            headerSection,
            heroCard,
            softwareSection,
            recomendationSection,
            contactSection,
            sideChanger,
          } = (data.value as IBaseResponse).data.attributes

          if (headerSection)
            this.headerSection = heroCard
              ? map.simpleHeaderHero({
                  ...headerSection,
                  heroCard
                })
              : map.simpleHeader(headerSection)
          if (softwareSection) this.softwareSection = softwareSection
          if (recomendationSection)
            this.recomendationSection = recomendationSection
          if (contactSection) this.contactSection = contactSection
          if (sideChanger) this.sideChanger = sideChanger
          this.shouldFetch = false
          this.loading = false
        } catch (error) {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },
  },
})

import { ref } from 'vue'
import useCss from '@/composables/useCss'
import { useWindow } from '@/composables/useWindow'
import { Colors } from '@/enums/Colors'
import { isClient } from '@/utils/isClient'

const menuStatus = ref(false)
const animation = ref(false)

export const useNavbar = () => {
  const { setProperty, removeClass, addClass } = useCss()
  const screen = useWindow()

  const setAnimation = (arg: boolean) => (animation.value = arg)
  const getStatus = computed(() => menuStatus.value)

  const clearMenu = () => {
    menuStatus.value = false
    animation.value = false
    setProperty('--vt-c-color-logo', Colors.WHITE)
    removeClass('navbar', 'is-active')
    if (isClient) document.body.classList.remove('body-cutter') 
  }

  const burgerButton = () => {
    animation.value = !animation.value
    animation.value
      ? setTimeout(() => (menuStatus.value = animation.value), 300)
      : (menuStatus.value = animation.value)
    if (animation.value) {
      addClass('navbar', 'is-active')
      if (screen.value.width <= 750) {
        setProperty('--vt-c-color-logo', Colors.DARK)
        document.body.classList.add('body-cutter')
      }
    } else clearMenu()
  }

  return {
    clearMenu,
    burgerButton,
    setAnimation,
    getStatus,
    animation,
    menuStatus,
  }
}

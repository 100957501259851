/* eslint-disable @typescript-eslint/no-explicit-any */
import * as map from '@/dataMappers/mapImage'

export const digitalPartners = (digitalPartner: any) => (digitalPartner ? {
  ...digitalPartner,
  digitalCards: digitalPartner.digitalCards?.map((elem: any) => ({
    ...elem,
    icon: map.image(elem.icon),
  })),
} : undefined)

export const softwareSolution = (softwareSolution: any) => (softwareSolution ? {
  ...softwareSolution,
  image: map.image(softwareSolution.image),
  additionSection: softwareSolution.additionSection ? {
    ...softwareSolution.additionSection,
    image: map.image(softwareSolution.additionSection.image),
  } : undefined,
  solutionsCard: softwareSolution.softwareSolutionCards.map((elem: any) => ({
    ...elem,
  })),
} : undefined)

export const partnersSection = (partnersSection: any) => (partnersSection ? {
  ...partnersSection,
  partners: partnersSection.partners.map((elem: any) => ({
    ...elem,
    logo: map.image(elem.logo)
  })),
} : undefined)

export const whyUsSection = (whyUsSection: any) => (whyUsSection ? {
  ...whyUsSection,
  reasonCards: whyUsSection.reasonCards.map((elem: any) => ({
    ...elem,
    icon: map.image(elem.icon)
  })),
} : undefined)

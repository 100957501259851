/* eslint-disable @typescript-eslint/no-explicit-any */
import * as map from '@/dataMappers/mapImage'

export const team = (team: any) => ({
  ...team,
  aboutTeamSection: team.aboutTeamSection ? {
    ...team.aboutTeamSection,
    image: map.image(team?.aboutTeamSection?.image),
  } : undefined,
})

export const ourOffer = (ourOffer: any) => ({
  ...ourOffer,
  benefitSections: ourOffer.benefitSections.data.map(
    (elem: any) => ({
      id: elem.id,
      benefitName: elem.attributes.benefitName,
    })
  ),
})

export const contact = (contactFrom: any, contactDescribe: any) => ({
  contactForm: contactFrom,
  contactDescribe: contactDescribe ? {
    ...contactDescribe,
    icons: map.images(contactDescribe?.icons)
  } : undefined,
})


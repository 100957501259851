<script setup lang="ts">
import LogoIcon from '@/components/media/LogoIcon.vue'
import MobileNavbar from '@/components/navbar/MobileNavbar.vue'
import { useNavbar } from '@/composables/useNavbar'
import { Routes, tabRoutesLink } from '@/enums/Routes'
import throttle from 'lodash.throttle'

const { clearMenu } = useNavbar()
const route = useRoute()
const getActiveUrl = (to: string) => (route.path === to)
const navigate = throttle((to: string) => {
  clearMenu()
  navigateTo(to)
}, 1000)
</script>

<template>
  <div id="navbar" class="fixed inset-0 w-screen h-fit">
    <div class="navbar">
      <nav
        class="flex justify-between xl:items-center w-[94%] mx-auto h-[100px]"
      >
        <NuxtLink
          id="logo-icon"
          aria-label="Home Page"
          :key="Routes.HOME"
          href="#"
          @click="navigate(Routes.HOME)"
          class="z-20"
        >
          <LogoIcon />
        </NuxtLink>
        <ClientOnly>
          <MobileNavbar class="flex xl:hidden z-10" />
        </ClientOnly>

        <div class="absolute left-[100%] xl:static">
          <NuxtLink
            v-for="(tab, index) in tabRoutesLink"
            :key="index"
            href="#"
            :aria-label="`navbar #${index}`"
            @click="navigate(tab.to)"
            :active="getActiveUrl(tab.to)"
          >
            {{ tab.name }}
          </NuxtLink>
        </div>
      </nav>
    </div>
    <div class="outside top-0" @click="clearMenu" />
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/animation/lineAnimation.css';
.outside {
  width: 100vw;
  height: 100vh;
  display: none;
  position: absolute;
  background-color: var(--vt-c-black);
  opacity: 0.7;
  z-index: -10;

  .is-active & {
    display: block;

    @media screen and (min-width: 1280px) {
      display: none;
    }
  }
}

.navbar {
  nav {
    padding: 28px 2%;
    width: 94%;
  }
}

.scrolled-full-height {
  #navbar:not(.is-active) {
    .navbar {
      background: var(--color-background-menu);
    }
  }
}

@media screen and (min-width: 750px) {
  .navbar {
    nav {
      padding: 28px 12px;
      width: 89.5%;

      a {
        position: relative;

        &:hover:not(#logo-icon)::after:not(:last-child) {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          background: var(--vt-c-text-light);
          height: 2px;
          width: 100%;
          animation: nav-underline 0.4s ease;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .navbar {
    nav {
      padding: 36px 12px;
      max-width: 1235px;

      a {
        margin-right: 40px;
        padding-right: 0;
        font-size: 16px;
        font-weight: 800;
        color: var(--vt-c-white);
        position: relative;
        width: auto;
        cursor: pointer;

        &:last-child {
          border: 1px solid var(--vt-c-white);
          border-radius: 6px;
          padding: 0.4rem 2.5rem;
          margin-left: 8px;
          margin-right: 0;
        }

        &[active='true']:not(#logo-icon):not(:last-child)::after,
        &:hover:not(#logo-icon):not(:last-child)::after {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          background: var(--vt-c-white);
          height: 2px;
          width: 100%;

          :not([active]) & {
            animation: nav-underline 0.4s ease;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1550px) {
  .navbar {
    nav {
      max-width: 76.5%;
    }
  }
}
</style>

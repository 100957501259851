import { defineStore } from "pinia";
import { guardedFetch } from './home.store'
import { IBaseResponse, IBasicSection } from "@/interface/IBasicSection";
import { IBasicHeaderHero } from "@/interface/IBasicHeaderHero";
import { map, mapHome } from "@/dataMappers";
import { ICommonSideChanger } from '@/interface/commonElements/ICommonSideChanger';
import { IPortfolioRecomendation } from '@/interface/portfolio/IPortfolioRecomendation';
import { IPortfolioFeedback } from '@/interface/portfolio/IPortfolioFeedback';
import { IPortfolioDigitalPartners } from '@/interface/portfolio/IPortfolioDigitalPartners';

export const usePortfolioStore = defineStore({
  id: 'portfolio',
  state: () => ({
    headerSection: <IBasicHeaderHero|null> null,
    digitalPartners: <IPortfolioDigitalPartners|null> null,
    technologySection: <IBasicSection|null> null,
    contactSection: <IBasicSection|null> null,
    sideChanger: <ICommonSideChanger|null> null,
    partnerFeedback: <IPortfolioFeedback|null> null,
    recomendation: <IPortfolioRecomendation|null> null,
    shouldFetch: true,
  }),
  getters: {
    getHeader: state => state.headerSection,
    getDigitalPartners: state => state.digitalPartners,
    getTechnologySection: state => state.technologySection,
    getContact: state => state.contactSection,
    getSideChanger: (state) => state.sideChanger,
    getPartnerFeedback: (state) => state.partnerFeedback,
    getRecomendation: (state) => state.recomendation,

  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        const config = useRuntimeConfig()

        const { data } = await useAsyncData('portfolio',
          () => $fetch(`${config.public.baseUrl}/api/portfolio-page?populate=deep`),
        )

        const {
          headerSection,
          softwareSolutionSection,
          technologySection,
          contactSection,
          portfolioSideChanger,
          partnerFeedback,
          recomendation
        } = (data.value as IBaseResponse).data.attributes

        if (headerSection) this.headerSection = map.simpleHeader(headerSection)
        if (softwareSolutionSection) this.digitalPartners = mapHome.softwareSolution(softwareSolutionSection)
        if (technologySection) this.technologySection = map.basicSection(technologySection)
        if (contactSection) this.contactSection = contactSection
        if (portfolioSideChanger) this.sideChanger = portfolioSideChanger
        if (partnerFeedback) this.partnerFeedback = partnerFeedback
        if (recomendation) this.recomendation = map.basicSection(recomendation)
        
        this.shouldFetch = false
      }, this.shouldFetch)
    },
  }
})

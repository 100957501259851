/* eslint-disable @typescript-eslint/no-explicit-any */
import * as map from '@/dataMappers/mapImage'
import { Routes } from '@/enums/Routes'
import { ICaseStudy } from '@/interface/ICaseStudy'
import { ICommonBlogPosts } from '@/interface/commonElements/ICommonBlogPosts'
import { IPortfolioRecomendations } from '@/interface/portfolio/IPortfolioRecomendation'

export const technologies = (technologies: any) => (
  technologies.data.map((elem: any) => ({
    ...elem.attributes,
    id: elem.id,
    icons: map.images(elem?.attributes?.icons),
  }))
)

export const panels = (panels: any) => (
  panels.data.map((elem: any) => ({
    ...elem.attributes,
    id: elem.id,
    image: map.image(elem?.attributes?.image),
  }))
)

export const blogPosts = (blogPosts: any): ICommonBlogPosts[] => {
  if (!blogPosts || !blogPosts.data) return []
  return blogPosts.data.map((elem: any) => ({
    id: elem.id,
    ...elem.attributes,
    mainImage: map.image(elem?.attributes?.mainImage),
    secondaryImage: map.image(elem?.attributes?.secondaryImage),
    blogPostTags: elem.attributes.blogPostTags.data.map(
      (tag: any) => tag.attributes.tagName
    ),
    date: elem.attributes.date.split('-').reverse().join('/'),
  }))
}

export const blogPosTags = (tags: any): string[] => {
  if (!tags || !tags.data) return ['All']
  return ['All', ...tags.data.map((elem: any) => elem?.attributes?.tagName)]
}

export const jobOffers = (jobs: any) => {
  const jobOfferTags = ['All']
  const jobOffers = jobs.data.map((elem: any) => {
    elem.attributes.tags.forEach((x: any) => {
      if (!jobOfferTags.includes(x)) jobOfferTags.push(x)
    })
    return {
      ...elem.attributes,
      id: elem.id,
      image: map.image(elem?.attributes?.image),
      offerDetails: elem.attributes.offerDetails ? {
        ...elem.attributes.offerDetails,
        image: map.image(elem.attributes?.offerDetails?.image),
      } : null,
    }
  })
  return {
    jobOffers,
    jobOfferTags,
  }
}

export const portfolios = (caseStudies: any[]): any => {
  const result = caseStudies.map(({ attributes: { title, copy, thumbnail, ...sections }, id }) => {

    return {
      id,
      title,
      copy,
      thumbnail: map.image(thumbnail),
      tags: sections.tags,
      background: map.image(sections?.background),
      firstSection: sections.firstSection ? {
        ...sections.firstSection,
        image: map.image(sections?.firstSection?.image)
      } : null,
      secondSection: sections.secondSection ? {
        ...sections.secondSection,
        image: map.image(sections?.secondSection?.image),
        icons: map.images(sections?.secondSection?.icons),
        video: sections.secondSection.video.data.attributes.url,
      } : null,
      thirdSection: {
        ...sections.thirdSection,
      },
      technologiesSection: sections?.technologiesSection?.map((el: any) => ({
        ...el,
        image: map.image(el?.image),
      })),
      contactSection: sections.contactSection ? {
        ...sections.contactSection,
        image: map.image(sections?.contactSection?.image),
      } : null,
      stepsSection: {
        ...sections.stepsSection,
      }
    }
  })

  return result
}

export const recomendations = (rec: any[]): IPortfolioRecomendations => {
  const result = rec.map((recomendation: any) => ({
    id: recomendation.id,
    mark: recomendation.attributes.mark,
    opinion: recomendation.attributes.opinion,
  }))

  return {
    bestOf: result.sort((a, b) => b.mark - a.mark).slice(0, 3),
    recomendations: result
  }
}

export const caseStudies = (data: any[]): ICaseStudy[] => {
  const result = data.map(({ attributes, id }: any) => ({
    id,
    thumbnail: map.image(attributes.thumbnail),
    tags: attributes.tags,
    headerSection: attributes.headerSection ? {
      ...attributes.headerSection,
      background: map.image(attributes?.headerSection?.background)
    } : null,
    firstSection: attributes.firstSection ? {
      ...attributes.firstSection,
      image: map.image(attributes?.firstSection?.image)
    } : null,
    secondSection: attributes.secondSection ? {
      ...attributes.secondSection,
      image: map.image(attributes?.secondSection?.image),
      recomendation: attributes?.secondSection?.recomendation ? {
        ...attributes?.secondSection?.recomendation,
        image: map.image(attributes?.secondSection?.recomendation?.image),
        icons: map.images(attributes?.secondSection?.recomendation?.icons),
      } : null,
      propositionSection: {
        ...attributes?.secondSection?.propositionSection,
        image: map.image(attributes?.secondSection?.propositionSection?.image)
      },
      techSection: {
        ...attributes?.secondSection?.techSection,
        image: map.image(attributes?.secondSection?.techSection?.image),
        icons: map.images(attributes?.secondSection?.techSection?.icons)
      },
      partnerSection: attributes?.secondSection?.partnerSection ? {
        ...attributes?.secondSection?.partnerSection,
        image: map.image(attributes?.secondSection?.partnerSection?.image)
      } : null
    } : null,
    thirdSection: attributes.thirdSection ? {
      ...attributes?.thirdSection,
      expertise: {
        ...attributes?.thirdSection?.expertise,
        image: map.image(attributes?.thirdSection?.expertise.image)
      },
      card: {
        ...attributes?.thirdSection?.card,
        image: map.image(attributes?.thirdSection?.card?.image)
      },
      collaboration: {
        ...attributes?.thirdSection?.collaboration,
      }
    } : null,
    urlText: attributes.urlText,
    logo: map.image(attributes.logo),
    videoSection: attributes.videoSection ? {
      ...attributes.videoSection,
      video: map.image(attributes?.videoSection?.video),
    } : null,
  })) as ICaseStudy[]

  return result
}

export const caseAndPortfolio = (caseAndPortfolio: any, type: Routes) => {
  return caseAndPortfolio ? caseAndPortfolio.map((el: any) => ({
    id: el.id,
    urlText: el?.urlText ? el.urlText : el.id.toString(),
    title: el?.firstSection?.title ?? '',
    copy: el?.firstSection?.copy ?? '',
    tags: el.tags,
    thumbnail: el.thumbnail,
    logo: el.logo,
    type
  })): []
}

export const footer = (footer: any) => ({
  id: footer.id,
  firstColumn: footer.attributes.firstSection ? {
    ...footer.attributes.firstSection,
    certified: map.image(footer.attributes.firstSection.certified)
  } : null,
  secondColumn: footer.attributes.secondColumn,
  thirdColumn: footer.attributes.thirdColumn,
  followSection: footer.attributes.followSection ? {
    ...footer.attributes.followSection,
    socialMediaSection: footer.attributes.followSection.socialMediaSection
      ? footer.attributes.followSection.socialMediaSection.map((el: any) => ({
        ...el,
        icon: map.image(el.icon)
      })) : []
  } : null
})

import { map, mapDeliver } from '@/dataMappers/index'
import { guardedFetch } from './home.store'
import { defineStore } from 'pinia'
import { IBaseResponse, IBasicSection } from '@/interface/IBasicSection'
import { IHomeHeader } from '@/interface/home/IHomeHeader'
import { ICommonSideChanger } from '@/interface/commonElements/ICommonSideChanger'
import { IDevelopmentSection } from '@/interface/deliver/IDevelopmentSection'
import { IBlogSection } from '@/interface/deliver/IBlogSection'

export const useDeliverStore = defineStore({
  id: 'deliver',
  state: () => ({
    headerSection: <IHomeHeader | null>null,
    developmentSection: <IDevelopmentSection | null>null,
    blogSection: <IBlogSection | null>null,
    contactSection: <IBasicSection | null>null,
    sideChanger: <ICommonSideChanger | null>null,
    shouldFetch: true,
    loading: false,
  }),
  getters: {
    getHeader: state => state.headerSection,
    getDevelopmentSection: state => state.developmentSection,
    getBlogSection: state => state.blogSection,
    getContactSection: state => state.contactSection,
    getSideChanger: state => state.sideChanger,
  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        try {
          this.loading = true
          const config = useRuntimeConfig()
          const { data } = await useAsyncData(
            'deliver-page',
            () =>
              $fetch(
                `${config.public.baseUrl}/api/deliver-page?populate=deep`
              ),
            { server: true }
          )

          const {
            headerSection,
            developmentSection,
            firstSection,
            secondSection,
            thirdSection,
            fourthSection,
            fifthSection,
            blogSection,
            contactSection,
            sideChanger,
          } = (data.value as IBaseResponse).data.attributes
          if (headerSection)
            this.headerSection = map.simpleHeader(headerSection)
          if (developmentSection)
            this.developmentSection = mapDeliver.developmentSection(
              developmentSection,
              firstSection,
              secondSection,
              thirdSection,
              fourthSection,
              fifthSection
            )
          if (blogSection) this.blogSection = blogSection
          if (contactSection) this.contactSection = contactSection
          if (sideChanger) this.sideChanger = sideChanger
          this.shouldFetch = false
          this.loading = false
        } catch (error) {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },
  },
})

<script setup lang="ts">
import NavbarComponent from '@/components/navbar/Navbar.vue'
import FooterComponent from '@/components/footer/footerComponent.vue'
import { isClient } from './utils/isClient'

const toggleScrollFullScreen = () => {
  if (window.scrollY >= window.innerHeight * 0.1) {
    document.body.classList.add('scrolled-full-height')
  } else {
    document.body.classList.remove('scrolled-full-height')
  }
}

onMounted(() => {
  if (isClient) window.addEventListener('scroll', toggleScrollFullScreen)
})

onBeforeUnmount(() => {
  if (isClient) window.removeEventListener('scroll', toggleScrollFullScreen)
})
</script>

<template>
  <div>
    <NavbarComponent class="z-20" />
    <NuxtPage />
    <FooterComponent />
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: filter 0.4s, opacity 0.7s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>

import type { IMarketContact } from '@/interface/devMarketplace/IMarketContact'
import type { IBaseResponse, IBasicSection } from '@/interface/IBasicSection'
import type { IBasicHeaderHero } from '@/interface/IBasicHeaderHero'
import { guardedFetch } from './home.store'
import { map, mapMarket } from '@/dataMappers'
import { defineStore } from 'pinia'

export const useMarketplaceStore = defineStore({
  id: 'marketplace',
  state: () => ({
    headerSection: <IBasicHeaderHero|null> null,
    jobOfferSection: <IBasicSection|null> null,
    scheduleSection: <IBasicSection|null> null,
    contactSection: <IMarketContact|null> null,
    shouldFetch: true,
    loading: false,
  }),
  getters: {
    getHeader: (state) => state.headerSection,
    getJobOffer: (state) => state.jobOfferSection,
    getSchedule: (state) => state.scheduleSection,
    getContact: (state) => state.contactSection,
  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        try {
          this.loading = true
          const config = useRuntimeConfig()
          const { data } = await useAsyncData('market-page',
            () => $fetch(`${config.public.baseUrl}/api/dev-marketplace-page?populate=deep`)
          )

          const {
            headerSection,
            jobSection,
            scheduleSection,
            contactSection,
          } = (data.value as IBaseResponse).data.attributes

          if (headerSection) this.headerSection = map.simpleHeaderHero(headerSection)
          if (jobSection) this.jobOfferSection = jobSection
          if (scheduleSection) this.scheduleSection = scheduleSection
          if (contactSection) this.contactSection = mapMarket.contact(contactSection)
          
          this.shouldFetch = false
          this.loading = false
        } catch (error) {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },
  },
})

<script setup lang="ts">
import { VueShowdown } from 'vue-showdown'

defineProps({
  htmlInput: {
    type: String,
    required: true,
  },
})
const showdownOptions = {
  simpleLineBreaks: true,
}
</script>

<template>
  <VueShowdown
    class="html-formatter flex flex-col"
    :markdown="htmlInput"
    :options="showdownOptions"
  />
</template>

<style lang="scss">
.html-formatter {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 500;

  & > code,
  & > p {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 500;

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  & > h3 {
    font-weight: bold;
    color: var(--vt-c-text-dark);
  }

  & > ul {
    display: flex;
    flex-direction: column;
    list-style-type: '- ' !important;

    & > li {
      font-family: 'Raleway', sans-serif;
      line-height: 22px;
      font-size: 16px;
      font-weight: 500;
      padding: 0;
    }
  }
}
</style>

import type { IContactDescribe } from '@/interface/contact/IContactDescribe'
import type { IContactHeader } from '@/interface/contact/IContactHeader'
import type { IContactMedia } from '@/interface/contact/IContactMedia'
import { guardedFetch } from './home.store'
import { map, mapContact } from '@/dataMappers'
import { defineStore } from 'pinia'
import { IBaseResponse } from '@/interface/IBasicSection'
import { ICommonSideChanger } from '@/interface/commonElements/ICommonSideChanger'
import { IEmailInput } from '@/interface/contact/IEmailInput'
import { IContactMeeting } from '@/interface/contact/IContactMeeting'

export const useContactStore = defineStore({
  id: 'contact',
  state: () => ({
    headerSection: <IContactHeader | null>null,
    describeSection: <IContactDescribe | null>null,
    mediaSection: <IContactMedia | null>null,
    sideChanger: <ICommonSideChanger | null>null,
    bookMeeting: <IContactMeeting | null>null,
    privacyPolicy: <string | null>null,
    shouldFetch: true,
    loading: false,
  }),
  getters: {
    getHeader: state => state.headerSection,
    getDescribe: state => state.describeSection,
    getMedia: state => state.mediaSection,
    getSideChanger: state => state.sideChanger,
    getPrivacyPolicy: state => state.privacyPolicy,
    getBookMeeting: state => state.bookMeeting,
  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        try {
          this.loading = true
          const config = useRuntimeConfig()
          const { data } = await useAsyncData('contact', () =>
            $fetch(`${config.public.baseUrl}/api/contact-us-page?populate=deep`)
          )

          const {
            headerSection,
            describeSection,
            mediaSection,
            contactSideChanger,
            privacyPolicy,
            bookMeeting,
          } = (data.value as IBaseResponse).data.attributes

          if (headerSection)
            this.headerSection = map.simpleHeader(headerSection)
          if (describeSection)
            this.describeSection = map.basicSection(describeSection)
          if (mediaSection) this.mediaSection = mapContact.media(mediaSection)
          if (contactSideChanger) this.sideChanger = contactSideChanger
          if (privacyPolicy)
            this.privacyPolicy =
              config.public.baseUrl + privacyPolicy?.data?.attributes?.url
          if (bookMeeting) this.bookMeeting = mapContact.meeting(bookMeeting)

          this.shouldFetch = false
          this.loading = false
        } catch (error) {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },

    async sendEMail(email: IEmailInput) {
      try {
        const config = useRuntimeConfig()
        await $fetch(`${config.public.baseUrl}/api/sendgrid`, {
          method: 'POST',
          body: email,
        })

        return true
      } catch {
        return false
      }
    },
  },
})

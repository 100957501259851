import { map } from '@/dataMappers'
import { defineStore } from 'pinia'
import { guardedFetch } from './home.store'
import type { IBasicHeaderHero } from '@/interface/IBasicHeaderHero'
import type { IServicePanel } from '@/interface/service/IServicePanel'
import type { IServiceTechnology } from '@/interface/service/IServiceTechnology'
import { IBaseResponse } from '@/interface/IBasicSection'
import { ICommonSideChanger } from '@/interface/commonElements/ICommonSideChanger'

export const useServiceStore = defineStore({
  id: 'services',
  state: () => ({
    headerSection: <IBasicHeaderHero|null> null,
    panelsSection: <IServicePanel|null> null,
    technologiesSection: <IServiceTechnology|null> null,
    sideChanger: <ICommonSideChanger|null> null,
    shouldFetch: true,
    loading: true,
  }),
  getters: {
    getHeader: (state) => state.headerSection,
    getPanels: (state) => state.panelsSection,
    getTechnologies: (state) => state.technologiesSection,
    getSideChanger: (state) => state.sideChanger,
  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        try {
          const config = useRuntimeConfig()
          this.loading = true
          const { data } = await useAsyncData('services',
            () => $fetch(`${config.public.baseUrl}/api/service?populate=deep`)
          )
          const {
            headerSection,
            technologySection,
            panelSection,
            serviceSideChanger,
          } = (data.value as IBaseResponse).data.attributes

          if (headerSection) this.headerSection = map.simpleHeader(headerSection)
          if (technologySection) this.technologiesSection = technologySection
          if (panelSection) this.panelsSection = panelSection
          if (serviceSideChanger) this.sideChanger = serviceSideChanger
          
          this.shouldFetch = false
          this.loading = false
        } catch {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },
  },
})

/* eslint-disable @typescript-eslint/no-explicit-any */
export const image = (img: any) => {
  if (!img?.data) return ''
  if (!(img.data?.attributes?.formats?.small)) return {
    aspect: `${img.data.attributes.width} / ${img.data.attributes.height}`,
    small: img.data.attributes.url,
    large: img.data.attributes.url,
  }
  if (img.data.attributes?.formats)
    return {
      aspect: `${img.data.attributes.width} / ${img.data.attributes.height}`,
      small: img.data.attributes.formats.small.url,
      large: img.data.attributes.url,
    }
}

export const images = (img: any) => {
  if (!img?.data) return []
  return img.data.map((elem: any) => ({
    aspect: `${elem.attributes.width} / ${elem.attributes.height}`,
    small: elem.attributes.url,
    large: elem.attributes.url,
  }))
}

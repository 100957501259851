import type { ICommonTechnology } from '@/interface/commonElements/ICommonTechnology'
import type { ICommonJobOffer } from '@/interface/commonElements/ICommonJobOffer'
import type { ICommonPanel } from '@/interface/commonElements/ICommonPanel'
import { guardedFetch } from './home.store'
import { defineStore } from 'pinia'
import type { ICommonBusinessCard } from '@/interface/commonElements/ICommonBusinessCard'
import type { ICommonBlogPosts } from '@/interface/commonElements/ICommonBlogPosts'
import { mapCommon, map } from '@/dataMappers'
import { IBaseResponse } from '@/interface/IBasicSection'
import { IPortfolio } from '@/interface/IPortfolio'
import { IPortfolioRecomendations } from '@/interface/portfolio/IPortfolioRecomendation'
import { ICaseAndPortfolio, ICaseStudy } from '@/interface/ICaseStudy'
import { Routes } from '@/enums/Routes'
import { IFooterSection } from '@/interface/commonElements/IFooterSection'

export const useCommonElementsStore = defineStore({
  id: 'common-elements',
  state: () => ({
    jobOffers: <ICommonJobOffer[] | null>null,
    jobOfferTags: <string[] | null>null,
    technologies: <ICommonTechnology[] | null>null,
    panels: <ICommonPanel[] | null>null,
    businessCard: <ICommonBusinessCard | null>null,
    blogPosts: <ICommonBlogPosts[] | null>null,
    blogPostTags: <string[] | null>null,
    portfolios: <IPortfolio[] | null>null,
    caseStudies: <ICaseStudy[] | null>null,
    recomendations: <IPortfolioRecomendations | null>null,
    caseAndPortfolio: <ICaseAndPortfolio[] | null>null,
    footerSection: <IFooterSection | null>null,
    shouldFetch: true,
    loading: false,
  }),
  getters: {
    getJobOffers: state => state.jobOffers,
    getJobOfferTags: state => state.jobOfferTags,
    getJobOfferById: state => (id: number) =>
      state.jobOffers?.find(elem => elem.id === id),
    getTechnologies: state => state.technologies,
    getPanels: state => state.panels,
    getBusinessCard: state => state.businessCard,
    getBlogPosts: state => state.blogPosts,
    getBlogPostTags: state => state.blogPostTags,
    getPortfolios: state => state.portfolios,
    getCaseStudies: state => state.caseStudies,
    getBestRecomendation: state => state.recomendations?.bestOf,
    getRecomendation: state => state.recomendations?.recomendations,
    getFooter: state => state.footerSection,
    getcaseAndPortfolio: state => {
      const portfolio = mapCommon.caseAndPortfolio(
        state.portfolios,
        Routes.PORTFOLIO
      )
      const caseStudies = mapCommon.caseAndPortfolio(
        state.caseStudies,
        Routes.CASE_STUDY
      )
      return [...portfolio, ...caseStudies]
    },
  },
  actions: {
    async fetchTechnologies() {
      await guardedFetch(async () => {
        try {
          const config = useRuntimeConfig()

          const { data } = await useLazyAsyncData(
            'technologies',
            () =>
              $fetch(`${config.public.baseUrl}/api/technologies?populate=deep`),
            { server: true }
          )
          if (data?.value)
            this.technologies = mapCommon.technologies(data.value)
        } catch {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },

    async fetchJobs() {
      await guardedFetch(async () => {
        try {
          const config = useRuntimeConfig()

          const { data } = await useLazyAsyncData(
            'job-offers',
            () =>
              $fetch(`${config.public.baseUrl}/api/job-offers?populate=deep`),
            { server: true }
          )
          if (data?.value) {
            const { jobOfferTags, jobOffers } = mapCommon.jobOffers(data.value)

            if (jobOffers) this.jobOffers = jobOffers
            if (jobOfferTags) this.jobOfferTags = jobOfferTags
          }
        } catch {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },

    async fetchPanels() {
      await guardedFetch(async () => {
        try {
          const config = useRuntimeConfig()
          const { data } = await useLazyAsyncData(
            'panels',
            () => $fetch(`${config.public.baseUrl}/api/panels?populate=deep`),
            { server: true }
          )
          if (data?.value) this.panels = mapCommon.panels(data.value)
        } catch {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },

    async fetchBusinessCard() {
      await guardedFetch(async () => {
        try {
          const config = useRuntimeConfig()
          const { data } = await useAsyncData(
            'business-card',
            () =>
              $fetch(
                `${config.public.baseUrl}/api/business-card?populate=deep`
              ),
            { server: true }
          )

          if (data?.value)
            this.businessCard = map.basicSection(
              (data.value as IBaseResponse)?.data?.attributes
            )
        } catch {
          console.info('Cannot download business card')
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },

    async fetchBlogPosts() {
      await guardedFetch(async () => {
        try {
          const config = useRuntimeConfig()
          const { data } = await useAsyncData(
            'blog-posts',
            () =>
              $fetch(`${config.public.baseUrl}/api/blog-posts?populate=deep`),
            { server: true }
          )
          if (data?.value)
            this.blogPosts = mapCommon.blogPosts(data.value)
        } catch {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },

    async fetchBlogPostTags() {
      await guardedFetch(async () => {
        try {
          const config = useRuntimeConfig()
          const { data } = await useAsyncData(
            'blog-post-tags',
            () =>
              $fetch(`${config.public.baseUrl}/api/blog-post-tags?populate=deep`),
            { server: true }
          )
          if (data?.value)
            this.blogPostTags = mapCommon.blogPosTags(data.value)
        } catch {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },

    async fetchPortfolios() {
      const config = useRuntimeConfig()

      const { data } = await useAsyncData(
        'portfolios',
        () => $fetch(`${config.public.baseUrl}/api/portfolios?populate=deep`),
        { server: true }
      )
      if (data?.value)
        this.portfolios = mapCommon.portfolios(
          (data.value as { data: unknown[] }).data
        )
    },

    async fetchRecomendations() {
      const config = useRuntimeConfig()

      const { data } = await useAsyncData(
        'recomendations',
        () =>
          $fetch(
            `${config.public.baseUrl}/api/star-rating-cards?populate=deep`
          ),
        { server: true }
      )
      if (data?.value) {
        this.recomendations = mapCommon.recomendations(
          (data.value as { data: unknown[] }).data
        )
      }
    },

    async fetchCaseStudies() {
      const config = useRuntimeConfig()

      const { data } = await useAsyncData(
        'case-studies',
        () => $fetch(`${config.public.baseUrl}/api/case-studies?populate=deep`),
        { server: true }
      )

      if (data?.value) {
        this.caseStudies = mapCommon.caseStudies(
          (data.value as { data: unknown[] }).data
        )
      }
    },

    async fetchFooterSection() {
      const config = useRuntimeConfig()

      const { data } = await useAsyncData(
        'footer-section',
        () =>
          $fetch(`${config.public.baseUrl}/api/footer-section?populate=deep`),
        { server: true }
      )

      if (data?.value) {
        this.footerSection = mapCommon.footer(
          (data.value as { data: unknown[] }).data
        )
      }
    },
  },
})

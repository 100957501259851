<script setup lang="ts">
import { computed, defineProps, ref, watch } from 'vue'

const prop = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
})
const activeAnimation = ref(false)
const firstActiveAnimation = ref(false)

watch(
  computed(() => prop.active),
  () => {
    prop.active ? activate() : excluded()
  }
)

const activate = () => {
  activeAnimation.value = prop.active
}

const excluded = () => {
  setTimeout(() => {
    activeAnimation.value = prop.active
  }, 800)
}

const animationControler = () => {
  if (prop.active) {
    firstActiveAnimation.value = true
    return true
  } else {
    if (firstActiveAnimation.value) {
      firstActiveAnimation.value = false
      return true
    } else return false
  }
}
</script>

<template>
  <div>
    <p
      id="white-block"
      v-show="activeAnimation"
      :class="`block block__1 ${animationControler() ? 'enter' : 'out'}`"
      style="animation-delay: 0ms"
    ></p>
    <p
      :id="`white-block-${item}`"
      v-for="item in 4"
      v-show="activeAnimation"
      :key="item"
      :class="`block block__${item + 1} ${
        animationControler() ? 'enter' : 'out'
      }`"
      :style="`animation-delay: ${item * 100}ms`"
    ></p>
    <slot />
  </div>
</template>

<style scoped lang="scss">
.block {
  height: 23vh;
  width: 100vw;
  top: 0;
  right: 0;
  background-color: var(--vt-c-white);
  position: fixed;

  &__2 {
    top: 20%;
  }

  &__3 {
    top: 40%;
  }

  &__4 {
    top: 60%;
  }

  &__5 {
    top: 80%;
  }
}

.enter {
  transform: translateX(-100%);
  animation: menu-block-in 500ms forwards;
  animation-iteration-count: 1;
}

.out {
  animation: menu-block-out 500ms forwards;
  animation-iteration-count: 1;
}

@media screen and (min-width: 750px) {
  .block {
    height: 1000px;
    width: 900px;
    position: absolute;
    border-radius: 50%;

    &__2,
    &__3,
    &__4,
    &__5 {
      display: none;
    }
  }

  .enter {
    animation: menu-circle-in 500ms forwards;
  }

  .out {
    animation: menu-circle-out 500ms forwards;
  }
}

@media screen and (min-width: 1300px) {
  .block {
    display: none;
  }
}

@keyframes menu-block-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes menu-block-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(+100%);
  }
}

@keyframes menu-circle-in {
  0% {
    transform: scale(0);
    right: -500px;
    top: -500px;
  }

  100% {
    transform: scale(1);
    right: -450px;
    top: -350px;
  }
}

@keyframes menu-circle-out {
  0% {
    transform: scale(1);
    right: -450px;
    top: -350px;
  }

  100% {
    transform: scale(0);
    right: -500px;
    top: -500px;
  }
}
</style>

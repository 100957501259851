import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig> {
  scrollBehavior({ hash }) {
    if (hash) {
      return {
        el: hash,
        top: 125,
      }
    }
    return { top: 0 }
  },
}

import type { IHomeSoftwareSoloution } from '@/interface/home/IHomeSoftwareSoloution'
import type { IHomeDigitalPartner } from '@/interface/home/IHomeDigitalPartner'
import type { IHomeInformation } from '@/interface/home/IHomeInformation'
import type { IHomeOurOffer } from '@/interface/home/IHomeOurOffer'
import type { IHomeHeader } from '@/interface/home/IHomeHeader'
import type { IBaseResponse, IBasicSection } from '@/interface/IBasicSection'
import { mapHome, map } from '@/dataMappers/index'
import { defineStore } from 'pinia'
import { IHomePartner } from '@/interface/home/IHomePartner'
import { IHomeWhyUs } from '@/interface/home/IHomeWhyUs'

export function guardedFetch(
  fetchFn: () => Promise<unknown>,
  shouldFetch: boolean
): Promise<unknown> | undefined {
  if (shouldFetch) {
    return fetchFn()
  }
}

export const useHomeStore = defineStore({
  id: 'home',
  state: () => ({
    headerSection: <IHomeHeader | null>null,
    informationSection: <IHomeInformation | null>null,
    digitalPartnersSection: <IHomeDigitalPartner | null>null,
    softwareSolutionSection: <IHomeSoftwareSoloution | null>null,
    jobOfferSection: <IBasicSection | null>null,
    technologySection: <IBasicSection | null>null,
    ourOfferSection: <IHomeOurOffer | null>null,
    partnersSection: <IHomePartner | null>null,
    whyUsSection: <IHomeWhyUs | null>null,
    shouldFetch: true,
    loading: false,
  }),
  getters: {
    getHeader: state => state.headerSection,
    getInformation: state => state.informationSection,
    getDigitalPartners: state => state.digitalPartnersSection,
    getSoftwareSolution: state => state.softwareSolutionSection,
    getJobOffer: state => state.jobOfferSection,
    getTechnology: state => state.technologySection,
    getOurOffer: state => state.ourOfferSection,
    getPartners: state => state.partnersSection,
    getWhyUs: state => state.whyUsSection,
  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        try {
          this.loading = true
          const config = useRuntimeConfig()

          const { data } = await useLazyAsyncData('home-page', () =>
            $fetch(`${config.public.baseUrl}/api/home-page?populate=deep`)
          )

          const {
            headerSection,
            informationSection,
            digitalPartnerSection,
            softwareSolutionSection,
            jobOfferSection,
            technologySection,
            ourOfferSection,
            partnersSection,
            whyUsSection,
          } = (data.value as IBaseResponse).data.attributes

          if (headerSection)
            this.headerSection = map.simpleHeader(headerSection)

          if (informationSection) this.informationSection = informationSection
          if (digitalPartnerSection)
            this.digitalPartnersSection = mapHome.digitalPartners(
              digitalPartnerSection
            )
          if (softwareSolutionSection)
            this.softwareSolutionSection = mapHome.softwareSolution(
              softwareSolutionSection
            )
          if (jobOfferSection) this.jobOfferSection = jobOfferSection
          if (technologySection) this.technologySection = technologySection
          if (ourOfferSection)
            this.ourOfferSection = map.basicSection(ourOfferSection)
          if (partnersSection)
            this.partnersSection = mapHome.partnersSection(partnersSection)
          if (whyUsSection)
            this.whyUsSection = mapHome.whyUsSection(whyUsSection)

          this.shouldFetch = false
          this.loading = false
        } catch {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },
  },
})

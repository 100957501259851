/* eslint-disable @typescript-eslint/no-explicit-any */
import * as map from '@/dataMappers/mapImage'

export const media = (media: any) => ({
  ...media,
  image: map.image(media?.image),
})

export const meeting = (meeting: any) => ({
  ...meeting,
  person: map.image(meeting?.person),
})


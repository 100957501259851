import { default as indexYS9nwkJJ7DMeta } from "/opt/app/pages/blog/[id]/index.vue?macro=true";
import { default as indexEohrYP9OICMeta } from "/opt/app/pages/blog/index.vue?macro=true";
import { default as _91id_933h4GxBI2j2Meta } from "/opt/app/pages/case-study/[id].vue?macro=true";
import { default as contactEMuyZ0eoNhMeta } from "/opt/app/pages/contact.vue?macro=true";
import { default as deliverymBkBNxh8v3Meta } from "/opt/app/pages/delivery.vue?macro=true";
import { default as index1JQZ1qn7X7Meta } from "/opt/app/pages/index.vue?macro=true";
import { default as our_45modelqDG1JkCI2AMeta } from "/opt/app/pages/our-model.vue?macro=true";
import { default as indexbVKO9vfTjKMeta } from "/opt/app/pages/portfolio/[id]/index.vue?macro=true";
import { default as indexGzvKdkEPBuMeta } from "/opt/app/pages/portfolio/index.vue?macro=true";
import { default as services11ZOCcTT7GMeta } from "/opt/app/pages/services.vue?macro=true";
import { default as softwareE68bC2TCrKMeta } from "/opt/app/pages/software.vue?macro=true";
export default [
  {
    name: indexYS9nwkJJ7DMeta?.name ?? "blog-id",
    path: indexYS9nwkJJ7DMeta?.path ?? "/blog/:id",
    meta: indexYS9nwkJJ7DMeta || {},
    alias: indexYS9nwkJJ7DMeta?.alias || [],
    redirect: indexYS9nwkJJ7DMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/blog/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEohrYP9OICMeta?.name ?? "blog",
    path: indexEohrYP9OICMeta?.path ?? "/blog",
    meta: indexEohrYP9OICMeta || {},
    alias: indexEohrYP9OICMeta?.alias || [],
    redirect: indexEohrYP9OICMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933h4GxBI2j2Meta?.name ?? "case-study-id",
    path: _91id_933h4GxBI2j2Meta?.path ?? "/case-study/:id",
    meta: _91id_933h4GxBI2j2Meta || {},
    alias: _91id_933h4GxBI2j2Meta?.alias || [],
    redirect: _91id_933h4GxBI2j2Meta?.redirect || undefined,
    component: () => import("/opt/app/pages/case-study/[id].vue").then(m => m.default || m)
  },
  {
    name: contactEMuyZ0eoNhMeta?.name ?? "contact",
    path: contactEMuyZ0eoNhMeta?.path ?? "/contact",
    meta: contactEMuyZ0eoNhMeta || {},
    alias: contactEMuyZ0eoNhMeta?.alias || [],
    redirect: contactEMuyZ0eoNhMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: deliverymBkBNxh8v3Meta?.name ?? "delivery",
    path: deliverymBkBNxh8v3Meta?.path ?? "/delivery",
    meta: deliverymBkBNxh8v3Meta || {},
    alias: deliverymBkBNxh8v3Meta?.alias || [],
    redirect: deliverymBkBNxh8v3Meta?.redirect || undefined,
    component: () => import("/opt/app/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: index1JQZ1qn7X7Meta?.name ?? "index",
    path: index1JQZ1qn7X7Meta?.path ?? "/",
    meta: index1JQZ1qn7X7Meta || {},
    alias: index1JQZ1qn7X7Meta?.alias || [],
    redirect: index1JQZ1qn7X7Meta?.redirect || undefined,
    component: () => import("/opt/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: our_45modelqDG1JkCI2AMeta?.name ?? "our-model",
    path: our_45modelqDG1JkCI2AMeta?.path ?? "/our-model",
    meta: our_45modelqDG1JkCI2AMeta || {},
    alias: our_45modelqDG1JkCI2AMeta?.alias || [],
    redirect: our_45modelqDG1JkCI2AMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/our-model.vue").then(m => m.default || m)
  },
  {
    name: indexbVKO9vfTjKMeta?.name ?? "portfolio-id",
    path: indexbVKO9vfTjKMeta?.path ?? "/portfolio/:id",
    meta: indexbVKO9vfTjKMeta || {},
    alias: indexbVKO9vfTjKMeta?.alias || [],
    redirect: indexbVKO9vfTjKMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/portfolio/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGzvKdkEPBuMeta?.name ?? "portfolio",
    path: indexGzvKdkEPBuMeta?.path ?? "/portfolio",
    meta: indexGzvKdkEPBuMeta || {},
    alias: indexGzvKdkEPBuMeta?.alias || [],
    redirect: indexGzvKdkEPBuMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/portfolio/index.vue").then(m => m.default || m)
  },
  {
    name: services11ZOCcTT7GMeta?.name ?? "services",
    path: services11ZOCcTT7GMeta?.path ?? "/services",
    meta: services11ZOCcTT7GMeta || {},
    alias: services11ZOCcTT7GMeta?.alias || [],
    redirect: services11ZOCcTT7GMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/services.vue").then(m => m.default || m)
  },
  {
    name: softwareE68bC2TCrKMeta?.name ?? "software",
    path: softwareE68bC2TCrKMeta?.path ?? "/software",
    meta: softwareE68bC2TCrKMeta || {},
    alias: softwareE68bC2TCrKMeta?.alias || [],
    redirect: softwareE68bC2TCrKMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/software.vue").then(m => m.default || m)
  }
]
<script setup lang="ts">
import { Colors } from '@/enums/Colors'
import useCss from '@/composables/useCss'
import { useWindow } from '@/composables/useWindow'
import { watch, computed } from 'vue'

const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
})

const { setProperty, addClass, removeClass } = useCss()
const screen = useWindow()

watch(
  computed(() => props.active),
  () => {
    if (props.active) {
      addClass('burger-btn', 'is-open')
      if (screen.value.width <= 750)
        setProperty('--vt-c-color-logo', Colors.DARK)
      setTimeout(
        () => setProperty('--vt-c-burger-btn-color', Colors.DARK),
        screen.value.width < 750 ? 300 : 200
      )
    } else {
      removeClass('burger-btn', 'is-open')
      setProperty('--vt-c-color-logo', Colors.WHITE)
      setTimeout(
        () => setProperty('--vt-c-burger-btn-color', Colors.WHITE),
        200
      )
    }
  }
)
</script>

<template>
  <div id="burger-btn">
    <div></div>
  </div>
</template>

<style scoped lang="scss">
#burger-btn {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;

  &::before,
  &::after {
    content: '';
  }

  &::before,
  &::after,
  div {
    display: block;
    position: absolute;
    background-color: var(--vt-c-burger-btn-color);
    width: 24px;
    height: 3px;
    transition: transform 0.5s;
    top: 3px;
  }

  div {
    top: 10px;
  }

  &::after {
    top: 17px;
  }

  &.is-open {
    --vt-c-burger-btn-color: var(--vt-c-text-dark);

    &::after {
      transform: translateY(-7px) rotate(-135deg);
    }

    &::before {
      transform: translateY(7px) rotate(135deg);
    }

    div {
      transform: scale(0) !important;
    }
  }
}
</style>

<script setup lang="ts">
import { useCommonElementsStore } from '@/store/commonElements.store'
import HtmlFormatter from '@/components/HtmlFormatter.vue'
import ImgComponent from '@/components/media/ImgComponent.vue';

const footer = useCommonElementsStore().getFooter
</script>

<template>
  <div class="flex relative bottom-0 w-full min-h-[25vh] bg-footer py-8">
    <div class="footer gap-2" v-if="footer">
      <div class="flex flex-col w-[23%] column min-w-[200px]" v-if="footer.firstColumn">
        <HtmlFormatter
          v-if="footer.firstColumn.contact"
          :html-input="footer.firstColumn.contact"
          class="w-max"
        />
      </div>
      <div class="flex flex-col w-[23%] column min-w-[200px]" v-if="footer.secondColumn">
        <h2>{{ footer.secondColumn.title }}</h2>
        <NuxtLink
          v-for="(tab, index) in footer.secondColumn.links"
          :key="index"
          :href="tab.link"
          aria-label="footer link"
          target="_blank"
          class="line--animation link w-fit cursor-pointer"
        >
          {{ tab.name }}
        </NuxtLink>
      </div>
      <div class="flex flex-col w-[23%] column min-w-[200px]" v-if="footer.thirdColumn">
        <h2>{{ footer.thirdColumn.title }}</h2>
        <NuxtLink
          v-for="(tab, index) in footer.thirdColumn.links"
          :key="index"
          :href="tab.link"
          aria-label="footer link"
          target="_blank"
          class="line--animation link w-fit cursor-pointer"
        >
          {{ tab.name }}
        </NuxtLink>
        <h2 class="mt-6 mb-2">{{ footer.firstColumn?.name ?? '' }}</h2>
        <ImgComponent
          v-if="footer.firstColumn && footer.firstColumn.certified"
          :src="footer.firstColumn.certified"
          alt="certified"
          class="h-[48px] object-contain w-fit"
        />
      </div>
      <div v-if="footer.followSection" class="follow-section min-w-[300px]">
        <h2>{{ footer.followSection.title ?? '' }}</h2>
        <div class="social-media">
          <NuxtLink
            v-for="(tab, index) in footer.followSection.socialMediaSection"
            :key="index"
            :href="tab.url ?? ''"
            aria-label="footer link"
            target="_blank"
            class="cursor-pointer"
          >
            <ImgComponent
              :src="tab.icon"
              :alt="`mediaIcon #${index}`"
              class="shake-element"
            />
          </NuxtLink>
        </div>
        <HtmlFormatter :html-input="footer.followSection.description" class="w-max" />
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import '../../assets/animation/lineAnimation.css';
@import '../../assets/animation/shakeAnimation.css';

.footer {
  display: flex;
  flex-wrap: wrap;
  width: var(--vt-c-application-width);
  margin: 0 auto;
  padding: 0 2%;

  h2 {
    color: var(--vt-c-white);
    font-weight: 650;
    font-size: 14px;
    width: max-content;
    margin-bottom: .3rem;
  }

  .follow-section {
    width: calc(31% - 3*8px);
  }

  .column {
    color: var(--vt-c-white);
    font-size: 12px;
    font-weight: 500;

    .link {
      position: relative;
      margin-bottom: .25rem;

      &:hover::before {
        content: '';
        position: absolute;
        bottom: -1px;
        background: var(--vt-c-white);
        height: 1px;
        width: 100%;
        animation: nav-underline 500ms;
        animation-timing-function: ease;
        animation-iteration-count: 1;
      }
    }
  }
  
  .social-media {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin: .5rem 0;

    img {
      width: 27px;
    }
  }

  :deep(.html-formatter) {
    width: 100%;
  }

  :deep(.html-formatter > p) {
    font-size: 14px;
    font-weight: 400 !important;
    color: var(--vt-c-white); 
  }
}

@media screen and (min-width: 750px) {
  .footer {
    padding: 0 12px;
  }

}
</style>
export default function useCss() {
  const setProperty = (prop: string, val: string) => {
    document.documentElement.style.setProperty(prop, val)
  }

  const addClass = (elem: string, token: string) => {
    document.getElementById(elem)?.classList.add(token)
  }

  const removeClass = (elem: string, token: string) => {
    document.getElementById(elem)?.classList.remove(token)
  }

  const changeBodyStyle = (prop: any, val: string) => {
    document.body.style[prop] = val
  }

  const changeElementStyle = (elem: string, prop: any, val: string) => {
    const element = document.getElementById(elem)
    if (element) {
      element.style[prop] = val
    }
  }

  return {
    changeElementStyle,
    changeBodyStyle,
    removeClass,
    setProperty,
    addClass,
  }
}

export enum Routes {
  HOME = '/',
  SERVICES = '/services',
  DEV_MARKET = '/dev-marketplace',
  PORTFOLIO = '/portfolio',
  CASE_STUDY = '/case-study',
  CAREERS = '/careers',
  OUR_MODEL = '/our-model',
  BLOG = '/',
  CONTACT = '/contact',
  JOBOFFER = '/job-offer',
  SOFTWARE = '/software',
  DELIVERY = '/delivery',

}

export enum RoutesName {
  SERVICES = 'Services',
  DEV_MARKET = 'Dev marketplace',
  CASE_STUDY = 'Case study',
  PORTFOLIO = 'Portfolio',
  CAREERS = 'Careers',
  OUR_MODEL = 'Our model',
  BLOG = 'Blog',
  CONTACT = 'Contact us!',
  HOME = 'Home',
  SOFTWARE = 'Software',
  DELIVERY = 'How we deliver',
}

export const tabRoutesLink = [
  {
    name: RoutesName.HOME,
    to: Routes.HOME,
  },
  {
    name: RoutesName.OUR_MODEL,
    to: Routes.OUR_MODEL,
  },
  {
    name: RoutesName.SERVICES,
    to: Routes.SERVICES,
  },
  // {
  //   name: RoutesName.DEV_MARKET,
  //   to: Routes.DEV_MARKET,
  // },
  {
    name: RoutesName.PORTFOLIO,
    to: Routes.PORTFOLIO,
  },
  // {
  //   name: RoutesName.CAREERS,
  //   to: Routes.CAREERS,
  // },
  // {
  //   name: RoutesName.SOFTWARE,
  //   to: Routes.SOFTWARE,
  // },
  // {
  //   name: RoutesName.DELIVERY,
  //   to: Routes.DELIVERY,
  // },
  // {
  //   name: RoutesName.BLOG,
  //   to: Routes.BLOG,
  // },
  {
    name: RoutesName.CONTACT,
    to: Routes.CONTACT,
  },
]

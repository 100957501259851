import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
    HiChevronDown, BiArrowLeft, BiArrowRight, BiHouseDoorFill, BiCodeSlash, GiGears, IoMap, CoMagnifyingGlass,
    HiChevronUp
} from "oh-vue-icons/icons";

addIcons(
    HiChevronDown, HiChevronUp, BiArrowLeft,
    BiArrowRight, BiHouseDoorFill, BiCodeSlash,
    GiGears, IoMap, CoMagnifyingGlass, HiChevronUp
);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("v-icon", OhVueIcon);
});
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as map from '@/dataMappers/mapImage'

export const postSide = (postSide: any) => ({
  ...postSide,
  facebookIcon: map.image(postSide?.facebookIcon),
  twitterIcon: map.image(postSide?.twitterIcon),
  linkedInIcon: map.image(postSide?.linkedInIcon),
})

export const categories = (categories: any) => ({
  ...categories,
  blogCardsSmall: categories.blogCardsSmall?.map(
    (elem: any) => ({
      ...elem,
      icon: map.image(elem?.icon),
    })
  ),
})
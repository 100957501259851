import { defineStore } from 'pinia'
import { guardedFetch } from './home.store'
import type { IHomeHeader } from '@/interface/home/IHomeHeader'
import type { IBlogPostSide } from '@/interface/blog/IBlogPostSide'
import type { IBlogCategories } from '@/interface/blog/IBlogCategories'
import type { IBlogNewsletter } from '@/interface/blog/IBlogNewsletter'
import { map, mapBlog } from '@/dataMappers'
import { IBaseResponse } from '@/interface/IBasicSection'
import { ICommonSideChanger } from '@/interface/commonElements/ICommonSideChanger'

export interface IPageTitles {
  recent: string
  mostViewed: string
  otherMostViewed: string
  newsletter: {
    title: string
    copy: string
  }
}

export const useBlogStore = defineStore({
  id: 'blog',
  state: () => ({
    headerSection: <IHomeHeader|null> null,
    postSideSection: <IBlogPostSide|null> null,
    categoriesSection: <IBlogCategories|null> null,
    newsletterSection: <IBlogNewsletter|null> null,
    latestArticlesSection: <string>'',
    sideChanger: <ICommonSideChanger|null> null,
    shouldFetch: true,
    loading: false,
  }),
  getters: {
    getHeader: (state) => state.headerSection,
    getSide: (state) => state.postSideSection,
    getCategories: (state) => state.categoriesSection,
    getNewsletter: (state) => state.newsletterSection,
    getLatestArticles: (state) => state.latestArticlesSection,
    getSideChanger: (state) => state.sideChanger,
  },
  actions: {
    async fetchData() {
      await guardedFetch(async () => {
        try {
          const config = useRuntimeConfig()
          this.loading = true

          const { data } = await useAsyncData('blog-page',
            () => $fetch(`${config.public.baseUrl}/api/blog-page?populate=deep`)
          )

          const {
            headerSection,
            postSideSection,
            categoriesSection,
            newsletterSection,
            latestArticlesSection,
            blogSideChanger,
          } = (data.value as IBaseResponse).data.attributes
          
          if (headerSection) this.headerSection = map.simpleHeader(headerSection)
          if (postSideSection) this.postSideSection = mapBlog.postSide(postSideSection)
          if (categoriesSection) this.categoriesSection = mapBlog.categories(categoriesSection)
          if (newsletterSection) this.newsletterSection = newsletterSection
          if (latestArticlesSection) this.latestArticlesSection = latestArticlesSection
          if (blogSideChanger) this.sideChanger = blogSideChanger
          this.shouldFetch = false
          this.loading = false
        } catch (error) {
          this.shouldFetch = true
        }
      }, this.shouldFetch)
    },
  },
})

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as map from '@/dataMappers/mapImage'

export const developmentSection = (dev: any, first: any, second: any, third: any, fourth: any, fifth: any) => ({
  ...dev,
  firstSection: first ? {
    ...first,
    list: first.list.map((elem: any) => ({
      ...elem,
      icon: map.image(elem.icon),
    })),
  } : undefined,
  secondSection: second ?? undefined,
  thirdSection: third ? {
    ...third,
    list: third.list.map((elem: any) => ({
      ...elem,
      icon: map.image(elem.icon),
    })),
  } : undefined,
  fourthSection: fourth ?? undefined,
  fifthSection: fifth ? {
    ...fifth,
    image: map.image(fifth.image),
  } : undefined
})

export const ourOffer = (ourOffer: any) => ({
  ...ourOffer,
  benefitSections: ourOffer.benefitSections.data.map(
    (elem: any) => ({
      id: elem.id,
      benefitName: elem.attributes.benefitName,
    })
  ),
})

export const contact = (contactFrom: any, contactDescribe: any) => ({
  contactForm: contactFrom,
  contactDescribe: contactDescribe ? {
    ...contactDescribe,
    icons: map.images(contactDescribe?.icons)
  } : undefined,
})

